import React from "react";
import rocket from '../assets/img/rocket.png';
import union from '../assets/img/union-rocket.png';
import { Button } from "antd";

const Section3: React.FC = () => {
    return (
        <div className="section3_wrapper">
            <div className="section3_text">
                <h3 style={{
                    color: '#303446',
                    fontSize: '38px',
                    lineHeight: '56px',
                    fontWeight: 600,
                    marginTop: 0,
                    marginBottom: '32px'
                }}>
                    Начни работать <br/> прямо сейчас</h3>
                <h5 style={{
                    color: '#303446',
                    fontSize: '24px',
                    lineHeight: '32px',
                    fontWeight: 600,
                    marginTop: 0,
                    marginBottom: '12px'
                }}>
                    Начать зарабатывать с Leads<br/> очень просто</h5>
                <div className="section3_table">
                    <p> <span>1.</span> Зарегистрируйся в системе</p>
                    <p> <span>2.</span> Подключи оффер</p>
                    <p> <span>3.</span> Лей качественный трафик</p>
                    <p> <span>4.</span> Зарабатывай на растущем рынке</p>
                </div>
                <a href="https://webmaster.leads.su/register" target='_blank' rel="noreferrer" className="button_link">
                    <Button type="primary" danger>
                        Зарегистрироваться
                    </Button>
                </a>
                
            </div>
            <div className="section3_img">
                <img src={rocket} alt='img_rocket' className="section3_img_rocket"/>
                <img src={union} alt='img_union' className="section3_img_union"/>
            </div>
        </div>
    )
}

export default Section3;