import React from "react";
import './section.css';
import woman from '../assets/img/woman.png';

const Section1: React.FC = () => {
    return (
        <div className="section1_wrapper">
            <div style={{margin: 'auto'}}>
                <p style={{
                    color: '#303446',
                    fontSize: '38px',
                    lineHeight: '56px',
                    fontWeight: 600,
                    margin: 0,
                    width: '499px'
                }}>
                    Нашими силами было <br/> привлечено более <br/>
                    <span style={{color: '#ED5252'}}> 100 000</span> клиентов
                </p>
            </div>

            <img src={woman} alt='img_people'/>
        </div>
    )
}

export default Section1;