import React from "react";
import targetboard from '../assets/img/targetboard.png';

const Section2: React.FC = () => {
    return (
        <div id="webmaster" className="section2_wrapper_background">
            <div className="section2_wrapper">
                <div className="section2_img">
                    <img src={targetboard} alt='img_targetboard'/>
                </div>
                <div className="section2_text">
                    <h3 style={{
                        fontSize: '38px',
                        lineHeight: '56px',
                        fontWeight: 600,
                        color: '#303446',
                        marginTop: 0,
                        marginBottom: '6px',
                    }}>Наши преимущества для вебмастера</h3>
                    <div>
                        <h5 className="section2_text_h5">Возможность диверсификации</h5>
                        <p className="section2_text_p">Масштабируйся на новые ГЕО</p>
                    </div>
                    <div>
                        <h5 className="section2_text_h5">Лучшие условия</h5>
                        <p className="section2_text_p">Получи лучшие ставки на рынке</p>
                    </div>
                    <div>
                        <h5 className="section2_text_h5">Быстрая и отзывчивая поддержка</h5>
                        <p className="section2_text_p">Оперативно решим любой вопрос</p>
                    </div>
                    <div>
                        <h5 className="section2_text_h5">Бонусная система</h5>
                        <p className="section2_text_p">Выполняй задания и получай бонус <br/>к своему доходу</p>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Section2;