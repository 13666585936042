import React from "react";
import './section.css';
import community from '../assets/img/community.svg';
import { Button } from "antd";
// import union from '../assets/img/union-head-1.png';
// import union375 from '../assets/img/union-head-2.png';

const SectionHead: React.FC = () => {
    return (
        <div className="section-head_wrapper">
            <h5 style={{
                color: '#A0A6BF',
                fontSize: '30px',
                lineHeight: '40px',
                marginBottom: 0,
                letterSpacing: '0.4em',
                paddingLeft: '8px',
                marginTop: 0
            }}>
                LEADS – ЭТО ПРОСТО!</h5>
            <p
            style={{
                color: '#303446',
                fontSize: '64px',
                lineHeight: '92px',
                fontWeight: 600,
                margin: 0

            }}>
                Заработай на динамично развивающемся рынке Казахстана
            </p>
            {/* <div className="section-head-union">
                <img src={union} alt='head-union'/>
            </div>
            <div className="section-head-union375">
                <img src={union375} alt='head-union375'/>
            </div> */}
            <div className="head-community-wrapper">
                <div className="head-community">
                    <div className="head-community-text">
                        <h5>Участвуй в Акции</h5>
                        <img src={community} alt="community"/>
                        <p>и зарабатывай еще больше</p>
                    </div>
                    <a href="https://webmaster.leads.su/register" target='_blank' rel="noreferrer" className="button_link">
                        <Button type="ghost" className="button-community">
                            Зарегистрироваться
                        </Button>
                    </a>
                </div>
            </div>
            
        </div>
    )
}

export default SectionHead;