import React from "react";

import zaimer from '../assets/img/zaimer.png';
import creditplus from '../assets/img/credit-plus.png';
import credit365 from '../assets/img/credit-365.png';
import keke from '../assets/img/keke.png';
import tengo from '../assets/img/tengo.png';
import hava from '../assets/img/hava.png';


const Partners: React.FC = () => {
    return (
        <div className="section_partners">
            <h3>Наши партнеры</h3>
            <div className="section_partners__partners">
                <div className="partners_img">
                    <img src={zaimer} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={creditplus} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={credit365} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={keke} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={tengo} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={hava} alt="icon_partner"/>
                </div>
            </div>
        </div>
    )
}

export default Partners;