import credit365 from '../assets/img/offers/credit365.svg';
import creditplus from '../assets/img/offers/credit-plus.svg';
import keke from '../assets/img/offers/keke.svg';
import zaimer from '../assets/img/offers/zaimer.svg';
import hava from '../assets/img/offers/hava.svg';
import tengo from '../assets/img/offers/tengo.svg';


export type OfferType = {
    logo: string,
    id: string,
    title: string,
    CR: string,
    AR: string,
    EPL: string,
    EPC: string,
    statistic: string,
    hold: string,
    payment: string,
    bonus: string
}

export const credit365Offer: OfferType = {
    logo: credit365,
    id: "ID 10106",
    title: "Credit365 [sale] KZ",
    CR: "27.1%",
    AR: "23.6%",
    EPL: "285.8",
    EPC: "67.7",
    statistic: "Раз в неделю",
    hold: "7 дней",
    payment: "1 150₽",
    bonus: "586"
}


export const creditplusOffer: OfferType = {
    logo: creditplus,
    id: "ID 9637",
    title: "CreditPlus [sale] KZ",
    CR: "23.0%",
    AR: "20.2%",
    EPL: "290",
    EPC: "66.8",
    statistic: "Раз в неделю",
    hold: "7 дней",
    payment: "1510₽",
    bonus: "769"
}

export const kekeOffer: OfferType = {
    logo: keke,
    id: "ID 8814",
    title: "Keke [sale] KZ",
    CR: "15.0%",
    AR: "19.9%",
    EPL: "340.68",
    EPC: "49.43",
    statistic: "Раз в неделю",
    hold: "7 дней",
    payment: "2081₽",
    bonus: "0"
}

export const zaimerOffer: OfferType = {
    logo: zaimer,
    id: "ID 698",
    title: "Займер [sale] KZ",
    CR: "12.3%",
    AR: "36.2%",
    EPL: "601.93₽",
    EPC: "63.96₽",
    statistic: "Раз в неделю",
    hold: "7 дней",
    payment: "1762₽",
    bonus: "898"
}

export const havaOffer: OfferType = {
    logo: hava,
    id: "ID 10551",
    title: "Hava [sale] KZ",
    CR: "24.3%",
    AR: "13.5%",
    EPL: "180.01₽",
    EPC: "43.79₽",
    statistic: "Раз в неделю",
    hold: "7 дней",
    payment: "920₽",
    bonus: "920"
}

export const tengoOffer: OfferType = {
    logo: tengo,
    id: "ID 1137",
    title: "Tengo [sale] KZ",
    CR: "13.3%",
    AR: "21.8%",
    EPL: "321.64₽",
    EPC: "43.38₽",
    statistic: "Раз в неделю",
    hold: "7 дней",
    payment: "1794₽",
    bonus: "920"
}