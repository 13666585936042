import { Button, Carousel } from "antd";
import React from "react";
import OfferCard from "./Offer";
import './section.css';
import { creditplusOffer, credit365Offer, kekeOffer, zaimerOffer, havaOffer, tengoOffer } from "./data-offers";


const SectionOffers: React.FC = () => {

    const onChange = (currentSlide: number) => {
        console.log(currentSlide);
      };

    return (
        <div className="section_offers">
            <h3>Наши офферы</h3>
            <div className="offers_container">
                <OfferCard {...credit365Offer}/>
                <OfferCard {...creditplusOffer}/>
                <OfferCard {...kekeOffer}/>
                <OfferCard {...zaimerOffer}/>
                <OfferCard {...havaOffer}/>
                <OfferCard {...tengoOffer}/>
            </div>
            <div className="carousel_offers">
                <Carousel afterChange={onChange}>
                    <OfferCard {...credit365Offer}/>
                    <OfferCard {...creditplusOffer}/>
                    <OfferCard {...kekeOffer}/>
                    <OfferCard {...zaimerOffer}/>
                    <OfferCard {...havaOffer}/>
                    <OfferCard {...tengoOffer}/>
                </Carousel>
            </div>
            <h5>Зарегистрируйся и получи доступ ко всем офферам Leads</h5>
            <a href="https://webmaster.leads.su/register" target='_blank' rel="noreferrer" className="button_link">
                <Button type="primary" danger>Зарегистрироваться</Button>
            </a>
            
        </div>
    )
}

export default SectionOffers;