import React from "react";
//import { Header } from "antd/es/layout/layout";
import logo from '../assets/icons/leads.png';
import { Button } from "antd";
import './header.css';
import union from '../assets/img/union-header-1.png';
import union375 from '../assets/img/union-header-375-full.png';
import unionhead from '../assets/img/union-head-1.png';
import unionhead375 from '../assets/img/union-head-375-full.png';

const HeaderSection: React.FC = () => {
    return (
        <div style={{position: 'relative', zIndex: '1', backgroundColor: '#FFFFFF'}}>
            <div className="header_wrapper">
                <div className="header_nav">
                    <div className="logo">
                        <img src={logo} alt='logo'/>
                    </div>
                    <nav>
                        <a className="nav-link active-link" href="#webmaster">Вебмастеру</a>
                    </nav>
                </div>
                <div className="header_authorization">
                    <a href="https://webmaster.leads.su/login" target='_blank' rel="noreferrer" className="button_link">
                        <Button danger style={{width: '85px', fontSize: '16px', lineHeight: '24px'}}>Войти</Button>
                    </a>
                    <a href="https://webmaster.leads.su/register" target='_blank' rel="noreferrer" className="button_link">
                        <Button style={{background: 'rgba(10, 14, 32, 0.07)', width: '142px', fontSize: '16px', lineHeight: '24px'}}>Регистрация </Button>
                    </a>
                </div>
            </div>
            <div className="section-header-union">
                <img src={union} alt='header-union'/>
            </div>
            <div className="section-header-union375">
                <img src={union375} alt='header-union375'/>
            </div>
            <div className="section-head-union">
                <img src={unionhead} alt='head-union'/>
            </div>
            <div className="section-head-union375">
                <img src={unionhead375} alt='head-union375'/>
            </div>
        </div>
    )
}

export default HeaderSection;